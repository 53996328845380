.root {
  position: relative;
  display: inline-block;
}

.plainContainer{
  background-color: red;
}

.tester{
  background-color: red;
  width: 100px;
  height: 100px;
}
