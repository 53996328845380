@import '../../../styles/customMediaQueries.css';


.root {
  /*   position: relative; */
  padding-top: 4px;
  /*  padding-bottom: 17px; */
  width: 120px;

  @media screen and (max-width: 740px) {
    width: 100%;
    border-bottom: 1px solid var(--colorGrey100);
  }
}

.filterHeader {
  margin-top: 5px;
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  margin-top: 20px;
  margin-bottom: 0px;
  padding: 0px 0 0px 0;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: -5px;
}

.label {
  color: var(--colorGrey700);
}

.labelSelected {
  color: var(--marketplaceColor);
  white-space: nowrap;
}

.labelButton {
  outline: none;
  line-height: 4px;
  text-align: left;
  border: none;
  padding: 0 0 0 4px ;
  width: 100%;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;
}

.openSign {
  float: right;
}

.formWrapper {
  padding-left: 5px;
  padding-top: 0px;
  max-width: 100px;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  /*   cursor: pointer; */

  @media (--viewportMedium) {
    margin: 0;
    padding: 4px 0 4px 0;
  }
}
