@import '../../../styles/customMediaQueries.css';


.searchHeaderWrapper {
  display: flex;
  flex-direction: column;
}
.searchOptions {
  display: flex;
  flex-direction: row;
  z-index: 8;
  position: relative;
  align-items: center;
  top: 10em;
  margin-left: 1em;

  @media (--viewportMedium) {
    top: 6em;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  @media (--viewportLarge) {
    top: 2em;
  }
  @media (--viewportXLarge) {
    top: 0;
  }

  /*
  composes: h4 from global;
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 18px;
  @media (--viewportMedium) {
    position: relative;
    top: 8em;
    margin-top: 8px;
    margin-bottom: 8px;
    color: #1c7881;
  }
  @media screen and (max-width: 560px) {
    padding-top: 180px;
    padding-left: 10px;
    padding-right: 10px;
  }*/
}

.searchResultSummary {
  composes: textSmall from global;

  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 0px;
  margin-right: 8px;
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 0 0 0;
  margin: 0;
}

.resultsFound {
  white-space: nowrap;
  color: #1c7881;
  font-size: 1.2em;
  @media screen and (max-width: 650px) {
    margin-left: 0px;
  }
}

.sortyByWrapper {
  display: inline-flex;
  margin-left: 1em;
  @media screen and (max-width: 765px) {
    display: none;
  }
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightRegular);
  padding: 8px 8px 8px 0;
}

.mainFilterWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  max-width: 60vw;
  flex-wrap: wrap;
  gap: 10px;
  width: fit-content;
}

/* .mainFilterWrapper > * {
  flex: 1 1 auto;
} */

@media screen and (max-width: 1530px) {
  .mainFilterWrapper {
    flex-direction: row;
    width: fit-content;
  }
}

@media screen and (max-width: 820px) {
  .mainFilterWrapper {
    margin-left: 45px;
    margin-top: 20px;
    width: fit-content;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.listingTypeFiltersWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  margin-left: 10px;
  @media screen and (max-width: 560px) {
    margin-left: 15px;
    /*     background-color: red; */
  }
}

.listingFilterButton {
  border: 1px solid black;
  background-color: #f6f6f6;
  border-radius: 12px;
  padding: 5px;
  width: 170px;
  color: black;
  will-change: transform;
  font-weight: 400;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 560px) {
    width: 110px;
    font-size: 11px;
  }

  &:active {
    transform: scale(0.97);
  }
}

.listingFilterButton:hover {
  cursor: pointer;
  border: 1px solid var(--marketplaceColorDark);
  color: var(--marketplaceColorDark);
  background-color: white;
}

.children {
  width: fit-content;
}

.selected {
  border: 1px solid var(--marketplaceColorDark);
  background-color: var(--marketplaceColor);
  color: #fff;

  &:hover{
    color: #fff;
    border: 1px solid var(--marketplaceColorDark);
    background-color: var(--marketplaceColorDark);
  }
}

.title {
  font-size: 1em;
  max-width: 700px;
  line-height: 1.2;
  text-wrap: wrap;

  @media (--viewportMedium) {
    font-size: 1.5em;
  }
}

.resultsFoundText{
  font-size: 1em;
  @media (--viewportMedium) {
    font-size: 1.2em;
  }
}
