@import '../../../styles/customMediaQueries.css';


.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 0;
  column-gap: 1em;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: center;
  }

  @media (--viewportMLarge) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-items: center;
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-items: center;
  }
}

.listingCards {
  padding: 0 0 72px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /*  gap: 24px; */
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.listingCard {
  width: 100%;
}

.listingCardOuterWrapper {
  padding: 1em;
  border-radius: 5px;
  background-color: white;
  box-shadow: var(--boxShadowListingCard);
  width: 100%;
  max-width: 100%;
  @media screen and (max-width: 778px) {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.cards_slider {
  overflow: visible;
  white-space: nowrap;
  height: auto;
  position: relative;

  left: 0;
  max-width: 680px;
}

.slider__controls {
  pointer-events: none;
  display: -webkit-box;
  display: flex;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 15px;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #1c7881;
  margin-right: 0px;
  right: 0px;
  z-index: 7;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media (--viewportMaxSmallDevice) {
    z-index: 7;
  }
  @media screen and (max-width: 850px) {
    margin-top: 38%;
  }
}

.slider__controls .btn:focus {
  outline: none;
}

.btn {
  pointer-events: all;
  padding: 2px;
  margin: 5px;
  border: none;
  width: 30px;
  height: fit-content;
  color: rgb(236, 236, 236);
}

.btn img {
  width: 13px;
  height: auto;
}

.btn:hover {
  cursor: pointer;
  /*   background: rgb(236, 236, 236); */
}

.favButton {
  pointer-events: none;
  position: relative;
  z-index: 5;
  bottom: 91%;
  text-align: right;
  border: none;
  margin-right: 1.1em;
}

.favImg {
  width: 35px;
  @media screen and (--viewportLarge) {
    width: 27px;
  }
  &:hover {
    transform: scale(1.1);
  }
  &:active {
    transform: scale(0.96);
  }
}

.favButtonChild {
  border: none;
  pointer-events: all;

  padding: 0;
}

.cardWrapper {
  width: 100%;
  max-width: 100%;
  max-height: 680px;
}

.counter {
  color: white;
  padding-top: 5px;
}

.nearbyLabelRow {
  grid-column: 1 / -1;
  text-align: center;
  padding: 1em 0;
  font-size: 1.2em;
  font-weight: bold;
}
